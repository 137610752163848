.App {
  text-align: center;
  background-color: #282a36;
}
.affix-container {

  background-color: #282a36;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content{
  background-color: #282a36;
}

.page-container{
  background-color: #282a36;
  min-height: 100vh;
}

.members-wrapper {
  position: relative;
}

.calculator-placeholder{
  color: white;
  padding-top: 10rem;
  padding-bottom: 10rem;
  font-size: 38px;
  justify-content: center;
  text-align: center;
}
.custom-link {
  color: orange; /* this will make the link color same as the text color */
  text-decoration: none; /* this will remove the underline */
}

.custom-link:hover {
  color: orange;
  text-decoration: underline; 
}
