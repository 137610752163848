@font-face {
  font-family: 'BAHNSCHRIFT';
  src: url('../fonts/BAHNSCHRIFT-6.TTF') format('truetype');
}

.navbar {
  display: flex;
  justify-content: space-between;
  margin-top: .55rem;
  align-items: center;
  background-color: #282a36;
  padding: 0.5rem 2rem;
}
  
  .nav-logo {
    padding-left: .5rem;
    padding-top: 1rem;
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
    font-family: 'BAHNSCHRIFT';

  }
 
  .nav-links {
    
    list-style-type: none;
    display: flex;
    align-items: center;
    font-family: 'BAHNSCHRIFT';
    margin: 0;
    padding: 20px;

  }
  
  .nav-item-about {
    margin-left: 2rem;
    color: #fff;
    text-decoration: none;
    font-size: 1.3rem;
    transition: color 0.3s;
    cursor: pointer;
  }
  
  .nav-item-mpc a {
    margin-left: 2rem;
    color: #fff;
    text-decoration: none;
    font-size: 1.3rem;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
  }

  .nav-banner-container {
    position: absolute;
    left: 50%;
    top: 4.0%;
    transform: translate(-50%, -50%);
  }
  
  .nav-banner {
    display: flex;
    color: #fff;
    align-items: center;
    font-family: 'BAHNSCHRIFT';
    font-size: 1.3rem;
  }
  
  
  .nav-item a {
    color: #fff;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.3s;
  }
  
  .nav-item a:hover {
    color: #ccc;
  }

  .search-form {
    display: flex;
    align-items: center;
  }
  
  .search-select {
    background-color: #fff;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .search-input {
    padding: 0.5rem;
    border: none;
    font-size: 1rem;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .search-btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    color: #fff;
    background-color: #5262ff;
    border: none;
    cursor: pointer;
    margin-left: 0.8rem;
    transition: background-color 0.3s;
  }
  
  .search-btn:hover {
    background-color: #777;
  }

  .search-label {
    font-size: 1rem;
    margin-right: .5rem;
    color: #fff;
  }
  
  
  .search-elements-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
  }

  .nav-item-dropdown {
    position: relative;
  }
  
  .nav-dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: inherit;
    font-family: 'BAHNSCHRIFT';
    font-size: 1.3rem;
  }

  .nav-dropdown-button:hover{
    background-color: dimgray;
  }
  
  .nav-dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: dimgray;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
    padding: 8px 0;
    min-width: 150px;
    z-index: 1000;
  }
  
  .nav-dropdown li {
    padding: 15px 16px;
    cursor: pointer;
    list-style-type: none;
  }
  
  .nav-dropdown li:hover {
    background-color: gray;
  }
  
  .nav-dropdown a {
    color: white;
    text-decoration: none;
  }
  
  .nav-dropdown a:hover {
    color: #000;
  }