.about-body{
    color: white;
    text-align: center;
    padding-bottom: 4rem;
    padding-top: 2rem;
}

.about-body .about-header1{
    color: inherit;
    text-decoration: underline;
}

.about-body .about-p1{
    color: inherit;
    font-size: 1.4rem;
}