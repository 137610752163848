.dungeon-breakdown-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
    padding-left: 2.3rem;
 
  }


  .button-container-load {
    text-align: center;
    border-radius: 10px;
  }

  .button-container-load button{
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 10px;
    color: #fff;
    background-color: #366f9e;
    border: none;
    cursor: pointer;
    margin-top: .5rem;
    margin-bottom: 10px;
  
    transition: 0.3s;
  }

  .button-container-load button:hover{
    background-color: #1f4666;
    cursor: pointer;
  }

  
  .member-dungeons {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*border: 3px solid #ccc;*/
    border-radius: 25px; 
    padding: 5px; 
    width: 90%;
    transition-duration: 0.1s;
    background-color:rgba(23, 27, 29, 0.548);
  }
/*
  .member-dungeons:hover {
    background-color: rgba(23, 27, 29, 0.548);
    -webkit-transform: scale(1.05);
  }
  */


  .member-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
    text-align: center;
  }
  
  .dungeon-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    /*align-items: center;*/
    padding: 5px 0;
    cursor: pointer;
    transition: 0.2;
  }
  
  .dungeon-name,
  .fdungeon-name,
  .tdungeon-name {
    color: white;
    text-align: center;
    justify-self: center; 
  }
  
  .dungeon-level,
  .fdungeon-level,
  .tdungeon-level {
    color: white;
    text-align: center;
    justify-self: center; 
  }
  
  .dungeon-time {
    color: white;
  }
  
  .dungeon-breakdown-title {
    font-size: 2rem;
    color: white;
    text-align: center;
    text-decoration: underline;
    padding: 15px;
    border-radius: 15px;
    padding-bottom: 25px;
  }
  
  .run-types {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
  
  .main-runs,
  .alternate-runs {
    width: 45%;
  }
  
  .tyrannical-runs,
  .fortified-runs {
    color: white;
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dungeon-breakdown-container {
    padding-bottom: 20px; 
  }

  .highlight {
    /*background-color: rgb(88, 113, 160);*/
    background-color: rgb(158, 32, 32);
  }