.footer {
    width: auto;
    background-color: #1d1f29;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .1rem;
    padding-top: .2rem;
    bottom: 0;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .01rem;
  }